var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Card", [
        _c("h1", [
          _vm._v("代码生成测试页 可将生成的代码全部粘贴至此test.vue文件"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }